import { useRef, useState } from 'react'
import { ProductLink } from '@/components/Product'
import Product from '@/helpers/Product'
import Carousel from './Carousel'

const LENS_SIZE = 50
const BORDER = '1px solid black'

// TODO: use ProductImages(for display) and Zoom(for preview) components instead of custom logic

const LENS_STYLES = {
  position: 'absolute',
  border: BORDER,
  height: `${LENS_SIZE}px`,
  width: `${LENS_SIZE}px`,
  zIndex: '100',
}

const ZOOM_STYLES = {
  border: BORDER,
  position: 'absolute',
  left: 'calc(100% + 1px)',
  top: '0',
  zIndex: '101',
}

const ImageZoomer = props => {
  const { className, pdpImages, images, mainImage } = props
  const lensRef = useRef()
  const baseRef = useRef()
  const zoomRef = useRef()
  const [imageIndex, setImageIndex] = useState(0)
  const [panningCoordinates, setPanningCoordinates] = useState({ x: 0, y: 0 })
  const [visibility, setVisibility] = useState({ display: 'none' })

  const cursorPosition = ({ pageX, pageY }) => {
    const { left, top } = baseRef.current.getBoundingClientRect()
    const x = pageX - left - window.pageXOffset
    const y = pageY - top - window.pageYOffset
    return { x, y }
  }

  const getPanningCoordinates = event => {
    const { x, y } = cursorPosition(event)
    return {
      x: Math.max(0, Math.min(x - (lensRef.current.offsetWidth / 2), baseRef.current.width - lensRef.current.offsetWidth)),
      y: Math.max(0, Math.min(y - (lensRef.current.offsetHeight / 2), baseRef.current.height - lensRef.current.offsetHeight)),
    }
  }

  const mouseEventHandler = outsideValue => event => {
    event.preventDefault()
    setPanningCoordinates(getPanningCoordinates(event))
    if (outsideValue != null) {
      setVisibility({ display: outsideValue ? 'none' : '' })
    }
  }

  const lensStyle = {
    ...LENS_STYLES,
    left: `${panningCoordinates.x + LENS_SIZE * 1.5}px`,
    top: `${panningCoordinates.y + LENS_SIZE / 2}px`,
    ...visibility
  }

  const getZoomedStyle = () => {
    const backgroundImage = `url('${Product.image({
      size: 'zoom',
      index: imageIndex,
      product: props,
    })}')`
    if (!zoomRef.current || !lensRef.current) {
      return { backgroundImage }
    }
    const xMag = zoomRef.current.offsetWidth / lensRef.current.offsetWidth
    const yMag = zoomRef.current.offsetHeight / lensRef.current.offsetHeight
    return {
      ...ZOOM_STYLES,
      backgroundPosition: `-${panningCoordinates.x * xMag}px -${panningCoordinates.y * yMag}px`,
      backgroundSize: `${baseRef.current.width * xMag}px ${baseRef.current.height * yMag}px`,
      backgroundImage,
      width: baseRef.current.width,
      height: baseRef.current.height,
      ...visibility,
    }
  }



  return (
    <section
      className={`quickView-image productView-images ${pdpImages} ${className}`}
      data-image-gallery
    >
      <figure className="productView-image">
        <div className="productView-img-container">
          <ProductLink {...props}>
            <div
              onMouseMove={mouseEventHandler()}
              onTouchMove={mouseEventHandler()}
              onMouseLeave={mouseEventHandler(true)}
              onMouseEnter={mouseEventHandler(false)}
            >
              <div style={lensStyle} ref={lensRef} />
              <img
                ref={baseRef}
                className="productView-image--default"
                src={Product.image({
                  size: 'standard', product: props, index: imageIndex,
                })}
              />
            </div>
          </ProductLink>
          <div ref={zoomRef} style={getZoomedStyle()} />
        </div>
      </figure>
      <Carousel
        above={4}
        images={images}
        mainImage={mainImage}
        onImageSelected={setImageIndex}
        title={Product.name({ source: props })}
      />
    </section>
  )

}

export default ImageZoomer
