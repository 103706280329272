import React, { useRef, useState } from 'react'
import { BlackLeftArrow, BlackRightArrow } from '@/components/Generic/Icon'
import Product from '@/helpers/Product'

const MAGIC_CONTROL_HEIGHT = '15px'
const MAGIC_WIDTH_OF_TILE = 105
const MAGIC_TRANSITION_DURATION = '0.3s'

const Carousel = ({
  images=[], mainImage, onImageSelected, title,
}) => {
  const containerRef = useRef()
  const [position, setPosition] = useState(0)

  const left = () => setPosition(position - 1)
  const right = () => setPosition(position + 1)

  const ensureInBounds = (index) => {
    if (index - position + 1 >= containerRef.current?.offsetWidth / MAGIC_WIDTH_OF_TILE) {
      right()
    }
  }

  const selectImageIndex = (index) => {
    onImageSelected(index)
    ensureInBounds(index)
  }

  const hasNext = () => (images.length - position) * MAGIC_WIDTH_OF_TILE
      > (containerRef.current?.offsetWidth || MAGIC_WIDTH_OF_TILE * 4)

  const hasPrevious = () => position !== 0

  const renderImages = () => [...images]
    .sort(({ sort_order: a }, { sort_order: b }) => a - b)
    .map((_, index) => {
      const src = Product.image({
        size: 'thumbnail',
        product: { images, mainImage },
        index,
      })
      return (
        <li
          key={index}
          data-testid={`carousel-image-${index}`}
          className="productView-thumbnail"
          onClick={selectImageIndex.bind(null, index)}
        >
          <img src={src} data-src={src} alt={title} title={title} />
        </li>
      )
    })

  return (
    <div style={{ marginTop: '15px' }}>
      <div ref={containerRef} style={{ overflow: 'hidden' }}>
        <ul
          className="productView-thumbnails"
          style={{
            width: '100vw',
            transform: `translate3d(-${position * MAGIC_WIDTH_OF_TILE}px, 0px, 0px)`,
            transition: `transform ${MAGIC_TRANSITION_DURATION} ease-in-out`,
          }}
        >
          {renderImages()}
        </ul>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {hasPrevious() ? (
          <div data-testid="carousel-previous" onClick={left}>
            <img style={{ height: MAGIC_CONTROL_HEIGHT }} src={BlackLeftArrow} />
            Previous
          </div>
        ) : ''}
        <div style={{ flexGrow: 1 }}>&nbsp;</div>
        {hasNext() ? (
          <div data-testid="carousel-next" onClick={right}>
            Next
            <img style={{ height: MAGIC_CONTROL_HEIGHT }} src={BlackRightArrow} />
          </div>
        ) : ''}
      </div>
    </div>
  )
}

export default Carousel
