import dynamic from 'next/dynamic'

const Loading = dynamic(import('@/components/Generic/Loading'))

const AjaxSpinner = ({ visible = false, className = '' }) => (
  <div
    data-testid="ajax-spinner"
    className={`${className}`}
    style={{
      display: visible ? 'flex' : 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff',
      opacity: '0.7',
    }}
  >
    <Loading height="200px" />
  </div>
)

export default AjaxSpinner
