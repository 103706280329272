/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { useCallback, useState } from 'react'
import styles from './Zoom.module.scss'

type ZoomProps = {
  img: string,
  zoomImg: string,
  zoomScale: number,
  height: number,
  width: number,
  transitionTime?: number,
}
const Zoom = ({
  height,
  img,
  transitionTime,
  width,
  zoomImg,
  zoomScale,
}: ZoomProps) => {
  const [imageNode, setImageNode] = useState<HTMLDivElement | null>(null)
  const [zoom, setZoom] = useState(false)
  const [{ mouseX, mouseY }, setMousePosition] = useState({ mouseX: 0, mouseY: 0 })

  const handleMouseOver = useCallback(() => setZoom(true), [setZoom])
  const handleMouseOut = useCallback(() => setZoom(false), [setZoom])

  const handleMouseMovement = useCallback((moveEvent) => {
    if (!imageNode) return
    const {
      left: offsetLeft, top: offsetTop, height: imageHeight, width: imageWidth,
    } = imageNode?.getBoundingClientRect()

    const x = ((moveEvent.clientX - offsetLeft) / parseInt(String(imageWidth), 10)) * 100
    const y = ((moveEvent.clientY - offsetTop) / parseInt(String(imageHeight), 10)) * 100

    setMousePosition({ mouseX: x, mouseY: y })
  }, [imageNode, setMousePosition])

  const transform = {
    transformOrigin: `calc(${mouseX}%) calc(${mouseY}%)`,
  }

  const outerDivStyle = {
    height: `${height}px`,
    width: `${width}px`,
    overflow: 'hidden',
  }

  const innerDivStyle = {
    height: `${height}px`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'auto 100%',
    transition: `transform ${transitionTime}s ease-out`,
    backgroundImage: `url('${zoom ? zoomImg : img}')`,
  }

  return (
    <div
      style={outerDivStyle}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={setImageNode}
    >
      <div
        style={{
          ...transform,
          ...innerDivStyle,
          transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
        }}
        className={styles.zoomImg}
      />
    </div>
  )
}

Zoom.defaultProps = {
  transitionTime: 0.1,
}

export default Zoom
