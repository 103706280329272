import Image from 'next/image'
import type { IBynderImage } from '@/types/Contentful/ContentfulBynderImage'
import { IMAGE_FALLBACK } from '@/helpers/Product'

interface BynderImageProps {
  image: IBynderImage
}

const bynderLoader = (
  src,
) => `${src}`

const BynderImage: React.FC<BynderImageProps> = ({
  image: {
    name, description, src, height, width,
  },
}) => (
  <Image
    src={src}
    onError={(e) => {
      console.error(`Bynder's file ${name} was not found on path ${src}`)
      e.currentTarget.src = IMAGE_FALLBACK
      e.currentTarget.onerror = null
    }}
    layout="responsive"
    loader={() => bynderLoader(src)}
    width={width}
    height={height}
    alt={description}
  />
)

export default BynderImage
